
import Vue from "vue";
export default Vue.extend({
  name: "BaseShape",

  props: {
    fillColor: { type: String, default: "black" },
    strokeColor: { type: String, default: "" },
    strokeWidth: { type: Number, default: 0 }
  }
});
