












import Vue from "vue";

import BaseShape from "./BaseShape.vue";

export default Vue.extend({
  mixins: [BaseShape],

  name: "RectangleShape",

  props: {
    height: { type: Number, default: 0 },
    width: { type: Number, default: 0 },
    originX: { type: Number, default: 0 },
    originY: { type: Number, default: 0 }
  }
});
