











import Point from "@/assets/Point";
import Vue from "vue";
import BaseShape from "./BaseShape.vue";

export default Vue.extend({
  mixins: [BaseShape],

  name: "LineShape",

  props: {
    origin: { type: Point, default: null },
    x2: { type: Number, default: 0 },
    y2: { type: Number, default: 0 }
  },

  computed: {
    x1(): number {
      return this.origin?.x || 0;
    },
    y1(): number {
      return this.origin?.y || 0;
    }
  }
});
