




































































































































































import Vue from "vue";
import axios from "axios";

import Image from "@/assets/Image";
import authHeader from "@/services/auth-header";
import { shapeTypes, shapeIcons } from "@/assets/Shape";
import { initialFillColor } from "@/store/typing";
import { API_URL } from "@/services/api";

import CanvasComponent, {
  CanvasRef
} from "@/components/images/CanvasComponent.vue";
import Modal from "@/components/Modal.vue";
import ButtonComponent from "@/components/form/ButtonComponent.vue";
import InputText from "@/components/form/InputText.vue";
import { Chrome } from "vue-color";

interface DataInterface {
  saveModalOpen: boolean;
  fillModalOpen: boolean;
  strokeModalOpen: boolean;
  image: Image;
  shapeTypes: typeof shapeTypes;
  shapeIcons: typeof shapeIcons;
  error: boolean;
  errorMessage: string;
}

const Editeur = Vue.extend({
  components: {
    CanvasComponent,
    Modal,
    ButtonComponent,
    InputText,
    "color-picker": Chrome
  },

  data(): DataInterface {
    return {
      // toggle various modals
      saveModalOpen: false,
      fillModalOpen: false,
      strokeModalOpen: false,
      // controls
      shapeTypes,
      shapeIcons,
      // image modal
      image: new Image(),
      // error handling
      error: false,
      errorMessage: ""
    };
  },

  computed: {
    loggedIn(): boolean {
      return this.$store.state.auth.loggedIn;
    },
    canvasComponent(): CanvasRef {
      return this.$refs.canvas as CanvasRef;
    },
    errorClass(): string {
      return this.error ? "border-danger" : "";
    },
    isDrawing(): boolean {
      return this.$store.state.editeur.isDrawing;
    },
    isEmpty(): boolean {
      return this.$store.state.editeur.shapes.length === 0;
    },

    fill(): string {
      return this.$store.state.editeur.fillColor.hex8;
    },
    stroke(): string {
      return this.$store.state.editeur.strokeColor.hex8;
    },

    shapeType: {
      get(): boolean {
        return this.$store.state.editeur.shapeType;
      },
      set(value: boolean) {
        this.$store.dispatch("editeur/setShapeType", value);
      }
    },

    strokeColor: {
      get(): typeof initialFillColor {
        return this.$store.state.editeur.strokeColor;
      },
      set(value: typeof initialFillColor) {
        this.$store.commit("editeur/setStrokeColor", value);
      }
    },

    fillColor: {
      get(): typeof initialFillColor {
        return this.$store.state.editeur.fillColor;
      },
      set(value: typeof initialFillColor) {
        this.$store.commit("editeur/setFillColor", value);
      }
    },

    strokeWidth: {
      get(): string {
        return this.$store.state.editeur.strokeWidth;
      },
      set(value: string) {
        this.$store.commit("editeur/setStrokeWidth", value);
      }
    },

    undoable(): boolean {
      return this.$store.state.editeur.historyIndex >= 0;
    },

    redoable(): boolean {
      return (
        !this.isDrawing &&
        this.$store.state.editeur.historyIndex <
          this.$store.state.editeur.history.length - 1
      );
    }
  },

  created() {
    this.$store.commit("editeur/new");
  },

  methods: {
    toggleSaveModal() {
      this.saveModalOpen = !this.saveModalOpen;
    },
    toggleFillModal() {
      this.fillModalOpen = !this.fillModalOpen;
    },
    toggleStrokeModal() {
      this.strokeModalOpen = !this.strokeModalOpen;
    },

    clearHandler() {
      this.$store.dispatch("editeur/clear");
    },

    undoHandler() {
      this.$store.dispatch("editeur/undo");
    },

    redoHandler() {
      this.$store.dispatch("editeur/redo");
    },

    downloadHandler() {
      this.readSvgContent();

      if (!this.error) {
        // browser -> download file
        const link = document.createElement("a");
        link.download = this.image.title + ".svg";
        link.href =
          "data:image/svg+xml;charset=utf-8," +
          encodeURIComponent(this.image.data);
        link.click();

        // toggle modal
        this.toggleSaveModal();
      }
    },

    saveHandler() {
      this.readSvgContent();

      if (!this.error) {
        // send image to back if authenticated
        axios
          .post(API_URL + "/api/images", this.image, {
            headers: authHeader()
          })
          .then(() => {
            this.toggleSaveModal();
            this.$store.dispatch("flash/flash", "Sauvegarde réussie ✔️");
          })
          .catch(error => {
            this.error = true;
            this.errorMessage = "Sauvegarde échouée.";
            console.error("sauvegarde échouée : ", error);
          });
      }
    },

    readSvgContent() {
      // check title has input
      const titleInput = document.getElementById("title") as HTMLInputElement;

      if (titleInput.value.trim() === "") {
        titleInput.classList.add("border-red-500");
        this.error = true;
        this.errorMessage = "Il faut entrer un titre.";
        return;
      }

      const canvas = this.canvasComponent.canvas;

      if (canvas === null) {
        this.error = true;
        this.errorMessage = "Erreur de lecture de l'image.";
        return;
      }

      const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${canvas?.clientWidth} ${canvas?.clientHeight}" stroke="black">
        ${canvas?.innerHTML}
        </svg>`;

      const title = titleInput.value.trim();

      const publicValue = (document.getElementById(
        "public"
      ) as HTMLInputElement)?.checked;
      this.image.public = publicValue;
      this.image.title = title;
      this.image.data = svgContent;

      this.error = false;
      this.errorMessage = "";
    }
  }
});
export default Editeur;
