









import Point from "@/assets/Point";
import Vue, { PropType } from "vue";
import BaseShape from "./BaseShape.vue";

export default Vue.extend({
  mixins: [BaseShape],

  name: "PolygonShape",

  props: {
    points: { type: Array as PropType<Point[]>, default: () => [] }
  },

  computed: {
    pointsComputed(): string {
      return this.points.map(p => p.x + "," + p.y).join(" ");
    }
  }
});
