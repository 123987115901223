









import Point from "@/assets/Point";
import Vue, { PropType } from "vue";
import BaseShape from "./BaseShape.vue";

export default Vue.extend({
  mixins: [BaseShape],

  name: "PathShape",

  props: {
    points: { type: Array as PropType<Point[]>, default: () => [] }
  },

  computed: {
    path(): string {
      let pathD = "";
      const length = Math.min(3, this.points.length);
      for (let i = 0; i < length; i++) {
        const p = this.points[i];
        let prefix = " ";
        if (i === 0) prefix = "M";
        else if (length < 3) prefix = " L";
        else if (i % 2 === 1) prefix = " Q";
        pathD += `${prefix}${p.x} ${p.y}`;
      }
      return pathD;
    }
  }
});
