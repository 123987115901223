




























import Vue from "vue";

import Point from "@/assets/Point";
import { Shape } from "@/assets/Shape";

import PolygonShape from "./canvasShapes/PolygonShape.vue";
import CircleShape from "./canvasShapes/CircleShape.vue";
import EllipseShape from "./canvasShapes/EllipseShape.vue";
import RectangleShape from "./canvasShapes/RectangleShape.vue";
import LineShape from "./canvasShapes/LineShape.vue";
import PathShape from "./canvasShapes/PathShape.vue";

interface DataInterface {
  canvas?: SVGElement;
  timer?: number;
  preventClickEvent: boolean;
  shapes: Shape[];
}

const CanvasComponent = Vue.extend({
  name: "CanvasComponent",

  components: {
    PolygonShape,
    CircleShape,
    EllipseShape,
    RectangleShape,
    LineShape,
    PathShape
  },

  props: {
    fillColor: { type: String, default: "#000000" },
    strokeColor: { type: String, default: "#0099EE" },
    strokeWidth: { type: Number, default: 1 }
  },

  data: function(): DataInterface {
    return {
      canvas: undefined,
      // managing dblclicks
      timer: undefined,
      preventClickEvent: false,
      shapes: []
    };
  },

  computed: {
    pointerClass(): string | null {
      return this.$store.state.editeur.isDrawing ? "pointer-events-none" : null;
    },
    currentShapeComponent(): string {
      return this.$store.state.editeur.shapeType + "Shape";
    },
    currentShape(): Shape {
      return this.$store.state.editeur.currentShape;
    },
    isDrawing(): boolean {
      return this.$store.state.editeur.isDrawing;
    }
  },

  watch: {
    "$store.state.editeur.shapes.length"() {
      this.shapes = this.$store.state.editeur.shapes;
    }
  },

  mounted() {
    this.canvas = this.$refs["canvas"] as SVGElement;
    this.canvas.setAttribute(
      "viewBox",
      `0 0 ${this.canvas.clientWidth} ${this.canvas.clientHeight}`
    );
    this.$store.commit("editeur/initializeShape");
  },

  methods: {
    initializeShape() {
      this.$store.commit("editeur/initializeShape");
    },

    previewDraw(e: MouseEvent) {
      // updates the shape in real time
      if (this.isDrawing) {
        const p = new Point(e.offsetX, e.offsetY);
        this.currentShape.preview(p);
      }
    },

    draw(e: MouseEvent) {
      // get click position
      const p = new Point(e.offsetX, e.offsetY);

      // test dblclick
      this.timer = setTimeout(() => {
        if (!this.preventClickEvent) {
          // no dblclick
          this.$store.commit("editeur/setDrawing", true);
          this.currentShape.draw(p);
        }

        this.preventClickEvent = false;
      }, 200); // max delay between dblclick
    },

    stopDrawing() {
      // prevent click event
      clearTimeout(this.timer);
      this.preventClickEvent = true;

      // close current shape and initializes new one
      this.$store.dispatch("editeur/stopDrawing");
    }
  }
});

export type CanvasRef = InstanceType<typeof CanvasComponent>;
export default CanvasComponent;
