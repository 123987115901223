












import Vue from "vue";

import Point from "@/assets/Point";
import BaseShape from "./BaseShape.vue";

export default Vue.extend({
  mixins: [BaseShape],

  name: "EllipseShape",

  props: {
    center: { type: Point, default: null },
    radiusX: { type: Number, default: 0 },
    radiusY: { type: Number, default: 0 }
  },

  computed: {
    cx(): number {
      if (this.center) return this.center.x;
      else return 0;
    },
    cy(): number {
      if (this.center) return this.center.y;
      else return 0;
    }
  }
});
